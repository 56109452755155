<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/turmas'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/turmas')"
              >Turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Turma {{ fastTurma.sigla_turma }}</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Turma
                  <span>{{ fastTurma.sigla_turma }}</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <transition name="slide-fade">
                    <div
                      v-if="fastTurma.id_turma"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Detalhes da turma {{ fastTurma.sigla_turma }}
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="m-0">
                                  Ativo:
                                  <span
                                    v-if="fastTurma.ativo == 'S'"
                                    class="text-success"
                                  >Sim</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Não</span>
                                </p>
                                <p class="m-0">
                                  Descrição:
                                  <span class="text-info">{{
                                    fastTurma.descricao_turma
                                  }}</span>
                                </p>
                                <p
                                  v-if="fastTurma.etapa"
                                  class="m-0"
                                >
                                  Etapa:
                                  <span class="text-info">{{
                                    fastTurma.etapa
                                  }}</span>
                                </p>                              
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-6">
                                <p class="m-0">
                                  Data de modificação:
                                  <span
                                    v-if="fastTurma.data_modifica"
                                    class="text-info"
                                  >{{
                                    new Date(fastTurma.data_modifica)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Nunca</span>
                                </p>
                                <p class="m-0">
                                  Data de cadastro:
                                  <span class="text-info">{{
                                    new Date(fastTurma.data_registro)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</span>
                                </p>                             
                                <p class="m-0">
                                  Turno:
                                  <span
                                    v-if="fastTurma.turno == 1"
                                    class="text-info"
                                  >Manhã</span>
                                  <span
                                    v-else-if="fastTurma.turno == 2"
                                    class="text-info"
                                  >Tarde</span>
                                  <span
                                    v-else-if="fastTurma.turno == 3"
                                    class="text-info"
                                  >Noite</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Indefinido</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de módulos
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Sequência</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Módulo</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ano</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Início do módulo</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Fim do módulo</small>
                                </th>

                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastTurma.programasLoading">
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastTurma.programas.length">
                              <tr
                                v-for="programa in fastTurma.programas"
                                :key="programa.id_programa_turma"
                              >
                                <td class="text-center align-middle">
                                  <small>{{ programa.sequencia }}</small>
                                </td>
                                <td class="align-middle">
                                  <small>{{ programa.sigla_programa }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>{{ programa.ano }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="programa.data_inicio">{{
                                    programa.data_inicio.split("-")[2] +
                                      "/" +
                                      programa.data_inicio.split("-")[1] +
                                      "/" +
                                      programa.data_inicio.split("-")[0]
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="programa.data_final">{{
                                    programa.data_final.split("-")[2] +
                                      "/" +
                                      programa.data_final.split("-")[1] +
                                      "/" +
                                      programa.data_final.split("-")[0]
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <a
                                    :href="
                                      '/plataforma/' +
                                        $route.params.id_plataforma +
                                        '/secretaria/turma/' +
                                        $route.params.id_turma +
                                        '/programa/' +
                                        programa.id_programa_turma
                                    "
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click.prevent="$router.push('/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/secretaria/turma/' +
                                      $route.params.id_turma +
                                      '/programa/' +
                                      programa.id_programa_turma)"
                                  >
                                    <small>Matriz curricular
                                      <span class="badge badge-light">{{
                                        programa.total_cursos
                                      }}</span></small>
                                  </a>
                                  <a
                                    :href="
                                      '/plataforma/' +
                                        $route.params.id_plataforma +
                                        '/secretaria/turma/' +
                                        $route.params.id_turma +
                                        '/programa/' +
                                        programa.id_programa_turma + '/matriculas'
                                    "
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2 d-none"
                                    @click.prevent="$router.push('/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/secretaria/turma/' +
                                      $route.params.id_turma +
                                      '/programa/' +
                                      programa.id_programa_turma + '/matriculas')"
                                  >
                                    <small>Matrículas <span class="badge badge-light">{{
                                      programa.total_matriculas
                                    }}</span></small>
                                  </a>

                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarPrograma(programa)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="6"
                                  class="text-center"
                                >
                                  Nenhum módulo cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/turmas'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/turmas')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->    
    <modal
      name="modalEditarPrograma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar módulo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPrograma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Sigla do módulo</label>
              <input
                :value="fastProgramaEditar.sigla_programa"
                class="form-control"
                type="text"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Ano</label>
              <input
                v-model="fastProgramaEditar.ano"
                class="form-control"
                type="number"
                placeholder="YYYY"
                min="2020"
                max="2100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Início das aulas</label>
              <input
                v-model="fastProgramaEditar.data_inicio"
                class="form-control"
                type="date"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Fim das aulas</label>
              <input
                v-model="fastProgramaEditar.data_final"
                class="form-control"
                type="date"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarPrograma()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirPrograma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir programa?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirPrograma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="excluirPrograma()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaTurma",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTurma: {
        ativo: "",
        data_modifica: "",
        data_registro: "",
        descricao_turma: "",
        etapa: "",
        id_plataforma: 0,
        id_turma: "",
        id_usuario: "",
        id_usuario_modifica: "",
        nome_plataforma: "",
        sigla_turma: "...",
        turno: "",
        id_secretaria_curso: 0,
        programasLoading: true,
        programas: [],
      },
      fastProgramaEditar: {
        id_programa_turma: "",
        ano: "",        
        data_inicio: "",
        data_final: "",
        id_plataforma: 0,
        id_turma: "",
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_turma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getFastProgramas();
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    // Turmas
    async getFastProgramas() {
      this.getFastTurma().then(async (turma) => {
        this.fastTurma = {
          ativo: turma.ativo,
          data_modifica: turma.data_modifica,
          data_registro: turma.data_registro,
          descricao_turma: turma.descricao_turma,
          etapa: turma.etapa,
          id_plataforma: turma.id_plataforma,
          id_turma: turma.id_turma,
          id_usuario: turma.id_usuario,
          id_usuario_modifica: turma.id_usuario_modifica,
          nome_plataforma: turma.nome_plataforma,
          sigla_turma: turma.sigla_turma,
          turno: turma.turno,
          id_secretaria_curso: turma.id_secretaria_curso,
          programasLoading: true,
          programas: [],
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_programa/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_turma=" +
              this.$route.params.id_turma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            obj.forEach((e) => {
              if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
              if (e.data_final) e.data_final = e.data_final.split("T")[0];              
            });
            this.fastTurma.programas = obj;
          }
          this.$store.state.fastCarregando = false;
          this.fastTurma.programasLoading = false
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.fastTurma.programasLoading = false
        }
      });
    },
    async getFastTurma() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_turma=" +
              this.$route.params.id_turma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json[0]);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    exibeModalEditarPrograma(programa) {
      this.fastProgramaEditar = programa;
      this.showModal("modalEditarPrograma");
    },
    async editarPrograma() {
      let erros = [];
      if (!this.fastProgramaEditar.ano) erros.push("O ano é obrigatório");
      if (!this.fastProgramaEditar.data_inicio)
        erros.push("A da início é obrigatória");
      if (!this.fastProgramaEditar.data_final)
        erros.push("A data de fim é obrigatória");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        const fast_secretaria_turma_programa = {
          id_programa_turma: this.fastProgramaEditar.id_programa_turma,
          ano: this.fastProgramaEditar.ano,
          data_inicio: this.fastProgramaEditar.data_inicio,
          data_final: this.fastProgramaEditar.data_final,
          id_plataforma: this.$route.params.id_plataforma,
          id_turma: this.$route.params.id_turma,
        };

        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_turma_programa/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma_programa))
          );

          let json = await resp.json();
          console.log(json);

          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastProgramas();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Programa editado com sucesso",
          });
          this.fastProgramaEditar = {
            id_programa_turma: "",
            ano: "",
            data_inicio: "",
            data_final: "",
            id_plataforma: 0,
            id_turma: "",
          };
          this.hideModal("modalEditarPrograma");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
